import { AsyncSelect as SUAsyncSelect } from "@stayunique/stayunique-ui";
import { Field } from "react-final-form";

//utils
import fetcher from "../../utils/fetcher";

type AsyncSelectType = {
    /**
     * Label of the field
     */
    label?: string;
    /**
     * placeholder of the field
     */
    placeholder?: string;
    /**
     * Name of the field
     */
    name: string;
    /**
     * placeholder Value
     */
    placeholderValue?: { value: string; label: string };
    /**
     * is the select Clearable
     */
    isClearable?: boolean;
};

/** AsyncSelect component */
const AsyncSelect = ({ name, placeholderValue, label, placeholder, isClearable }: AsyncSelectType) => {
    async function fetchResults(inputValue: string): Promise<any> {
        try {
            const result = await fetcher(`messages/search?search=${encodeURI(inputValue)}`);

            return result.bookings.map((booking: any) => {
                return {
                    value: booking.id,
                    label: booking.customerName + " - " + booking.refId
                };
            });
        } catch (e) {
            throw e;
        }
    }

    return (
        <Field name={name}>
            {({ input }) => <SUAsyncSelect label={label} placeholder={placeholder} onChange={input.onChange} fetchResults={fetchResults} isClearable={isClearable} defaultValue={placeholderValue} />}
        </Field>
    );
};

export default AsyncSelect;
