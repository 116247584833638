import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Loader } from "@stayunique/stayunique-ui";

//fields
import AsyncSelect from "./fields/AsyncSelect";
import Checkbox from "./fields/Checkbox";
import Uploader from "./fields/Uploader";
import Select from "./fields/Select";
import TextField from "./fields/TextField";

//utils
import fetcher from "../utils/fetcher";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    appBar: {
        position: "relative"
    }
}));

function Edit() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<{ id: any }>();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [expense, setExpense] = useState<any>({});
    const [propertiesList, setPropertiesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            try {
                const expense = await fetcher(`/ex/view/${id}`);

                setExpense(expense.data);

                const categories = await fetcher("ex/categories");

                setCategoryList(categories.data);

                const suppliers = await fetcher("ex/suppliers");

                setSuppliersList(suppliers.data);

                const properties = await fetcher("properties");

                setPropertiesList(properties.data);
            } catch (e) {
                enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
            }

            setLoading(false);
        }
        fetchData();
    }, [id, enqueueSnackbar, t]);

    let submit = async () => {};

    const handleSave = async () => {
        setIsDisabled(true);

        try {
            await submit();
            enqueueSnackbar(t("edit.saveSuccess"), { variant: "success", preventDuplicate: true });
        } catch (e) {
            enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
        }

        setIsDisabled(false);
    };

    const save = async (values: any) => {
        try {
            if (!values.propertyId) throw new Error("Property is required");

            await fetcher(`/ex/update/${id}`, {
                method: "POST",
                body: JSON.stringify(values)
            });
        } catch (e) {
            throw e;
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            history.push("/");
                        }}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label={t("misc.back")}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        {t("edit.title")}
                    </Typography>

                    <IconButton onClick={handleSave} disabled={isDisabled} color="inherit" aria-label={t("edit.save")}>
                        <Save />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <div className={classes.content}>
                <Form
                    onSubmit={save}
                    mutators={{
                        ...arrayMutators
                    }}
                    keepDirtyOnReinitialize
                    initialValues={expense}
                    render={({ handleSubmit }: any) => {
                        submit = handleSubmit;

                        return (
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6" color="inherit">
                                    {t("edit.information")}
                                </Typography>
                                <Typography variant="caption" display="block">
                                    {t("edit.total")}
                                </Typography>

                                <TextField type="number" name="total" multiline fullWidth />

                                <Select
                                    label={t("edit.supplier")}
                                    options={suppliersList.map((supplier: any) => ({
                                        value: supplier.supplierId,
                                        label: supplier.supplierName
                                    }))}
                                    name="supplierId"
                                    isClearable
                                />

                                <Select
                                    label={t("edit.property")}
                                    options={propertiesList.map((property: any) => ({
                                        value: property.propertyId,
                                        label: property.propertyName
                                    }))}
                                    name="propertyId"
                                    isClearable
                                />

                                <Select
                                    label={t("edit.category")}
                                    options={categoryList.map((category: any) => ({
                                        value: category.categoryId,
                                        label: category.categoryName
                                    }))}
                                    name="supplierCategoryId"
                                    isClearable
                                />

                                <AsyncSelect
                                    label={t("edit.booking")}
                                    name="bookingId"
                                    placeholderValue={expense.booking && { value: expense.booking.bookingId, label: `${expense.booking.customerName} - ${expense.booking.refId}` }}
                                    isClearable
                                />

                                <Select
                                    label={t("edit.paymentMethod")}
                                    options={[
                                        { value: "1", label: "Card" },
                                        { value: "2", label: "Cash" },
                                        { value: "3", label: "Transfer" }
                                    ]}
                                    name="paymentMethodId"
                                    isClearable
                                />

                                <Typography variant="caption" display="block">
                                    {t("edit.description")}
                                </Typography>

                                <TextField name="description" multiline fullWidth />

                                <Checkbox label={t("edit.vatInvoice")} name="vatInvoice" />

                                <br />

                                <Typography variant="h6" color="inherit">
                                    {t("edit.receipt")}
                                </Typography>

                                <Uploader name="files" uploadType="expense" destination="expenses" />
                            </form>
                        );
                    }}
                />
            </div>
        </div>
    );
}

export default Edit;
