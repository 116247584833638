import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Loader } from "@stayunique/stayunique-ui";

//fields
import AsyncSelect from "./fields/AsyncSelect";
import Checkbox from "./fields/Checkbox";
import Uploader from "./fields/Uploader";
import Select from "./fields/Select";
import TextField from "./fields/TextField";

//utils
import fetcher from "../utils/fetcher";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    appBar: {
        position: "relative"
    }
}));

function New() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<{ id: any }>();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [propertiesList, setPropertiesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            try {
                const categories = await fetcher("ex/categories");

                setCategoryList(categories.data);

                const suppliers = await fetcher("ex/suppliers");

                setSuppliersList(suppliers.data);

                const properties = await fetcher("properties");

                setPropertiesList(properties.data);
            } catch (e) {
                enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
            }

            setLoading(false);
        }
        fetchData();
    }, [id, enqueueSnackbar, t]);

    let submit = async () => {};

    const handleSave = async () => {
        setIsDisabled(true);

        try {
            await submit();
            enqueueSnackbar(t("add.addSuccess"), { variant: "success", preventDuplicate: true });
        } catch (e: any) {
            enqueueSnackbar(e.message || t("misc.error"), { variant: "error", preventDuplicate: true });
        }

        setIsDisabled(false);
    };

    const save = async (values: any) => {
        if (!values.propertyId) throw new Error("Property is required");

        await fetcher(`/ex/new`, {
            method: "POST",
            body: JSON.stringify(values)
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            history.push("/");
                        }}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label={t("misc.back")}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        {t("add.title")}
                    </Typography>

                    <IconButton onClick={handleSave} disabled={isDisabled} color="inherit" aria-label={t("add.add")}>
                        <Add />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <div className={classes.content}>
                <Form
                    onSubmit={save}
                    mutators={{
                        ...arrayMutators
                    }}
                    keepDirtyOnReinitialize
                    initialValues={{ files: [] }}
                    render={({ handleSubmit }: any) => {
                        submit = handleSubmit;

                        return (
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6" color="inherit">
                                    {t("add.information")}
                                </Typography>
                                <Typography variant="caption" display="block">
                                    {t("add.total")}
                                </Typography>

                                <TextField type="number" name="total" multiline fullWidth />

                                <Select
                                    label={t("add.supplier")}
                                    options={suppliersList.map((supplier: any) => ({
                                        value: supplier.supplierId,
                                        label: supplier.supplierName
                                    }))}
                                    name="supplierId"
                                    isClearable
                                />

                                <Select
                                    label={t("add.property")}
                                    options={propertiesList.map((property: any) => ({
                                        value: property.propertyId,
                                        label: property.propertyName
                                    }))}
                                    name="propertyId"
                                    isClearable
                                />

                                <Select
                                    label={t("add.category")}
                                    options={categoryList.map((category: any) => ({
                                        value: category.categoryId,
                                        label: category.categoryName
                                    }))}
                                    name="supplierCategoryId"
                                    isClearable
                                />

                                <AsyncSelect label={t("add.booking")} name="bookingId" isClearable />

                                <Select
                                    label={t("add.paymentMethod")}
                                    options={[
                                        { value: "1", label: "Card" },
                                        { value: "2", label: "Cash" },
                                        { value: "3", label: "Transfer" }
                                    ]}
                                    name="paymentMethodId"
                                    isClearable
                                />

                                <Typography variant="caption" display="block">
                                    {t("add.description")}
                                </Typography>

                                <TextField name="description" multiline fullWidth />

                                <Checkbox label={t("add.vatInvoice")} name="vatInvoice" />

                                <br />

                                <Typography variant="h6" color="inherit">
                                    {t("add.receipt")}
                                </Typography>

                                <Uploader name="files" uploadType="expense" destination="expenses" />
                            </form>
                        );
                    }}
                />
            </div>
        </div>
    );
}

export default New;
