import { Checkbox as SUCheckbox } from "@stayunique/stayunique-ui";
import { Field } from "react-final-form";

type CheckboxType = {
    /**
     * Label of the field
     */
    label?: string;
    /**
     * Name of the field
     */
    name: string;
};

/** Checkbox component */
const Checkbox = ({ name, label }: CheckboxType) => {
    return (
        <Field type="checkbox" name={name}>
            {({ input }) => <SUCheckbox label={label} checked={input.checked} onChange={input.onChange} />}
        </Field>
    );
};

export default Checkbox;
