import EuroSymbol from "@material-ui/icons/EuroSymbol";

const configuration = {
    appName: "expenseApp",
    appIcon: <EuroSymbol />,
    primary: "#f44336",
    secondary: "#e57373"
};

export default configuration;
