import { useState, useEffect } from "react";
import { Uploader as SUUploader } from "@stayunique/stayunique-ui";
import { Field } from "react-final-form";

//utils
import fetcher from "../../utils/fetcher";

type UploaderType = {
    /**
     * Name of the field
     */
    name: string;
    /**
     * upload destination
     */
    destination: string;
    /**
     * upload type
     */
    uploadType: string;
};

const UploaderWrapper = ({ onChange, value, destination, uploadType }: any) => {
    const [values, setValues] = useState(value || []);

    useEffect(() => {
        onChange(values);
    }, [values, onChange]);

    const change = (value: string) => {
        setValues((prev: string[]) => [...prev, value]);
    };

    const createSignedURL = async (file: any): Promise<any> => {
        return await fetcher("upload", {
            method: "POST",
            body: JSON.stringify({ destination: destination, upload_type: uploadType, filename: file.name, contentType: file.type })
        });
    };

    const deleteFile = async (index: number) => {
        await fetcher("upload", {
            method: "DELETE",
            body: JSON.stringify({
                key: value[index].split(".com/").pop()
            })
        });

        let newValue = [...values];

        if (index !== -1) {
            newValue.splice(index, 1);
        }

        setValues(newValue);
    };

    return <SUUploader createSignedURL={createSignedURL} onChange={change} values={values} onDelete={deleteFile} />;
};

/** Uploader component */
const Uploader = ({ name, destination, uploadType }: UploaderType) => {
    return <Field name={name}>{({ input }) => <UploaderWrapper destination={destination} uploadType={uploadType} value={input.value} onChange={input.onChange} />}</Field>;
};

export default Uploader;
