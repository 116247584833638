import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Theme } from "@material-ui/core/styles/createTheme";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        padding: theme.spacing(),
        whiteSpace: "pre-wrap"
    },
    button: {
        margin: theme.spacing()
    }
}));

function Help() {
    const classes = useStyles();
    const { t } = useTranslation();

    const history = useHistory();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            history.push("/");
                        }}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label={t("misc.back")}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        {t("help.title")}
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.content}>
                <Typography variant="body1">{t("help.helpDesc")}</Typography>
                <br />
                <Typography variant="body1">
                    STAY U-NIQUE S.L.
                    <br />
                    B63666887
                    <br />
                    Tuset 19 3º 5ª
                    <br />
                    08006 Barcelona
                    <br />
                    93 275 04 23
                </Typography>
            </div>
        </div>
    );
}

export default Help;
