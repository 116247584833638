import { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import HelpIcon from "@material-ui/icons/Help";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Loader } from "@stayunique/stayunique-ui";

// utils
import fetcher from "../utils/fetcher";

// components
import Navigation from "./Layout/Navigation";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    content: {
        flexGrow: 1
    },
    fab: {
        margin: theme.spacing(1),
        top: "auto",
        right: 10,
        bottom: 10,
        left: "auto",
        position: "fixed"
    }
}));

function ExpensesList() {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    moment.locale(i18n.language);

    const [expensesList, setExpensesList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            try {
                const properties = await fetcher("ex/list");

                setExpensesList(properties.data);
            } catch (e) {
                enqueueSnackbar(t("misc.error"), {
                    variant: "error",
                    preventDuplicate: true
                });
            }

            setLoading(false);
        }

        fetchData();
    }, [enqueueSnackbar, t]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Navigation
                buttons={
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            history.push("/help/");
                        }}
                        aria-label={t("list.help")}
                    >
                        <HelpIcon />
                    </IconButton>
                }
            />

            <main className={classes.content}>
                <List>
                    {expensesList.map(expense => {
                        const now = moment();
                        const created = moment(expense.dateCreated);

                        const diffDays = now.diff(created, "days");

                        return (
                            <ListItem
                                key={expense.supplierInvoiceId}
                                button
                                component="a"
                                onClick={() => {
                                    history.push(`/edit/${expense.supplierInvoiceId}`);
                                }}
                            >
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography
                                            variant="h6"
                                            style={
                                                expense.hasFiles === 1
                                                    ? {
                                                          textDecoration: "line-through",
                                                          color: "grey"
                                                      }
                                                    : diffDays > 1
                                                    ? { color: "red" }
                                                    : {}
                                            }
                                        >
                                            {expense.supplierName ? expense.supplierName : <i>{t("list.noSupplier")}</i>}
                                            {" - €"}

                                            {expense.total}
                                        </Typography>
                                    }
                                    secondary={<Typography variant="body2">{moment(expense.dateCreated).format("DD/MM/YY HH:mm")}</Typography>}
                                />
                            </ListItem>
                        );
                    })}
                </List>
                <Fab
                    color="primary"
                    aria-label={t("misc.add")}
                    className={classes.fab}
                    onClick={() => {
                        history.push("/new");
                    }}
                >
                    <AddIcon />
                </Fab>
            </main>
        </div>
    );
}

export default ExpensesList;
