import { Select as SUSelect } from "@stayunique/stayunique-ui";
import { Field } from "react-final-form";

type SelectType = {
    /**
     * Class name to apply
     */
    className?: string;
    /**
     * Label of the field
     */
    label?: string;
    /**
     * placeholder of the field
     */
    placeholder?: string;
    /**
     * Name of the field
     */
    name: string;
    /**
     * Options
     */
    options: { value: string; label: string }[];
    /**
     * is the select Clearable
     */
    isClearable?: boolean;
};

/** Select component */
const Select = ({ className, name, options, label, placeholder, isClearable }: SelectType) => {
    return (
        <Field name={name}>
            {({ input }) => <SUSelect placeholder={placeholder} className={className} label={label} options={options} onChange={input.onChange} isClearable={isClearable} value={input.value} />}
        </Field>
    );
};

export default Select;
