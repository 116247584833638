import React from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";

//components
import Login from "./components/Login";
import Help from "./components/Help";
import Main from "./components/Main";
import Settings from "./components/Settings";
import Add from "./components/Add";
import Edit from "./components/Edit";
import PrivateRoute from "./components/PrivateRoute";

//context
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "./context/ConfigContext";

//config
import "./i18n/config";

function App() {
    return (
        <ConfigProvider>
            <CssBaseline />
            <SnackbarProvider>
                <AuthProvider>
                    <Router>
                        <Switch>
                            <PrivateRoute exact path="/" component={Main} />
                            <Route exact path="/login" component={Login} />
                            <PrivateRoute exact path="/settings" component={Settings} />
                            <PrivateRoute exact path="/help" component={Help} />
                            <PrivateRoute exact path="/new" component={Add} />
                            <PrivateRoute exact path="/edit/:id" component={Edit} />
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </AuthProvider>
            </SnackbarProvider>
        </ConfigProvider>
    );
}

export default App;
